/* eslint-disable no-undef */
<template>
  <b-overlay rounded="sm">
    <b-form class="campaign-detail my-2 my-md-0">
      <div class="infoFormImg">
        <b-img :src="require(`@/assets/images/infoForm.png`)" />
      </div>
      <div class="px-2 py-4 ml-8 ml-md-0 content">
        <div class="bigTitle">Step 1: Stay Connected</div>
        <div class="textContent" style="margin-bottom: 8px;">
          Don't miss out on exclusive opportunities! Share your WhatsApp to receive updates on
          the latest campaigns and special offers.
        </div>
        <div class="youCanTextTitle" style="position: relative;">
          Tiktok Channel Username *
          <b-link href="#" @click="handleToggleMaxImage(require(`@/assets/images/infoFormExample.png`), true)"
            @mouseover="handleToggleMaxImage(require(`@/assets/images/infoFormExample.png`), true)"
            @mouseout="handleToggleMaxImage('', false)" style="color: #4F00C5;white-space: nowrap;margin-left: 24px;">
            What's Username?</b-link>
          <div v-if="showMaxImage"
            @mouseover="handleToggleMaxImage(require(`@/assets/images/infoFormExample.png`), true)"
            @click="handleToggleMaxImage('', false)" @mouseout="handleToggleMaxImage('', false)">
            <b-img :src="viewSrc" class="viewImg" />
          </div>
        </div>
        <div style="position: relative;width: 100%;">
          <b-form-group>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text">@</span>
              </b-input-group-prepend>
              <!-- 输入框 -->
              <b-form-input v-model="form.userName" type="text" placeholder="Enter your Tiktok Channel Username"
                class="mb-2" @blur.native="() => { handleValidate(validateObj, 'userName') }" />
            </b-input-group>
            <b-form-invalid-feedback :state="validateObj.userName">
              Required
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="youCanTextTitle">WhatsApp *</div>
        <b-form-group>
          <b-input-group>
            <!-- 前缀 -->
            <b-input-group-prepend>
              <b-form-select v-model="selectedCountryCode" :options="$store.state.country.countryCodes"
                class="input-group-text" />
            </b-input-group-prepend>
            <!-- 输入框 -->
            <b-form-input v-model="form.whatsapp" type="text" placeholder="Enter your WhatsApp number" class="mb-2"
              @blur.native="() => { customValidate(validateObj, 'whatsapp') }" />
          </b-input-group>
          <b-form-invalid-feedback :state="validateObj.whatsapp">
            {{ customErrorMsg }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="bigTitle">Step 2: Exclusive Birthday Perks Just for You!</div>
        <div class="textContent" style="margin-bottom: 18px;">
          Let us celebrate you the way you deserve! Share your birth month to receive a special gift and exclusive
          collaboration opportunities.
        </div>
        <div class="youCanTextTitle">Birthday</div>
        <div class="birthBox">
          <div style="display: flex;width: 45%;">
            <!-- 前缀 -->
            <span class="input-group-text">MM</span>
            <b-form-group style="margin-left: -5px;">
              <b-form-select v-model="form.birthMonth" :options="months" :placeholder="'MM'"
                style="min-width: 180px;" />
            </b-form-group>
          </div>

          <div style="margin-left: 12px;display: flex;width: 45%;">
            <!-- 前缀 -->
            <span class="input-group-text">DD</span>
            <b-form-group style="margin-left: -5px;">
              <b-form-select v-model="form.birthDay" :options="days" :placeholder="'DD'" style="min-width: 180px;" />
            </b-form-group>
          </div>


        </div>
        <div class="youCanTextTitle">Area *</div>
        <div style="display: flex;width: 97%;">
          <b-form-group label-for="country_short" class="required-tag" style="width: 33%;">
            <CountryPicker v-model="selectedCountry" :has-placeholder="false" />
            <b-form-invalid-feedback :state="validateObj.country">
              Required
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="state" class="required-tag" style="width: 33%;margin-left: 12px;">
            <b-form-select v-if="form.country === 'US'" id="state" v-model="form.state" :state="validateObj.state"
              :options="$store.state.country.addressSummary[form.country]" required placeholder=" State/Province" />
            <b-form-input v-else id="state_province" v-model="form.state" :state="validateObj.state"
              placeholder=" State/Province" />
            <b-form-invalid-feedback :state="validateObj.state">
              Required
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label-for="city" class="required-tag" style="width: 33%;margin-left: 12px;">
            <b-form-input id="city" v-model="form.city" required placeholder="City" :state="validateObj.city" />
            <b-form-invalid-feedback :state="validateObj.city">
              Required
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="bigTitle" style="margin-top: 12px;">Step 3: Partnership Preferences</div>
        <div class="textContent">
          Help us match you with perfect campaigns
        </div>
        <div class="youCanTextTitle" style="margin-top: 20px;">Your preferred product category:</div>
        <b-form-group label="" label-for="platform" style="width: 100%;">
          <MultiSelect v-model="selectedCategory" multiple placeholder="" value="value" required
            :clear-on-select="false" label="name" :close-on-select="false" track-by="name" :options="platformOptions"
            v-bind="$attrs" v-on="$listeners" @remove="handleSelect" @select="handleSelect" style="width: 97%;">
            <template slot="option" slot-scope="props">
              <div class="option__title">
                <span>
                  {{ props.option.name }}
                </span>
                <b-icon v-if="props.option.check" class="position-absolute" icon="check" />
              </div>
            </template>
          </MultiSelect>
        </b-form-group>
        <div class="d-flex justify-content-center" style="margin-top: 12px;">
          <b-button @click="submit" class="apply-btn">
            Submit
          </b-button>
        </div>
        <div class="youCanTextTitle " style="margin-top: 26px;margin-bottom: 11px;">
          Data Protection:
        </div>
        <div class="textContent">• 256-bit SSL Encrypted Submission</div>
        <div class="textContent">• We will never disclose any of your private information,see our
          <a href="https://campaign.gotvoom.com/agreement/privacyPolicy" class="text-ep" style="color: #4F00C5;">Privacy
            Policy</a>
        </div>
      </div>

    </b-form>
  </b-overlay>
</template>
<script>
import CountryPicker from '@/components/countryPicker/index.vue'
import MultiSelect from 'vue-multiselect'
import datePicker from 'vuejs-datepicker'
import { Component, Vue } from 'vue-property-decorator'
import { saveInfluencerInfoCollection, unsubscribed, subscribed, recordButtonClick } from '@/api/campaign'
export default {
  name: 'CampaignsDetail',
  components: {
    CountryPicker,
    MultiSelect,
    datePicker
  },
  watch: {
    selectedCountry(val) {
      this.form.country = val.code
    }
  },
  data() {
    return {
      showConfirmDialog: false,
      timer: null,
      showTime: true,
      showLoading: false,
      selectedCountryCode: '+1',
      selectedCountry: {
        code: 'US',
      },
      selectedCategory: [],
      form: {
        influencerId: '',
        userName: '',
        whatsapp: '',
        birthMonth: '',
        birthDay: '',
        country: '',
        state: '',
        city: '',
        category: [],
        categoryTem: [],
      },
      validateObj: {
        whatsapp: null,
        country: null,
        state: null,
        city: null,
        category: null,
        userName: null
      },
      platformOptions: [
        { name: 'Shopping & Retail', value: 'Shopping & Retail', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Home, Furniture & Appliances', value: 'Home, Furniture & Appliances', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Beauty & Fashion', value: 'Beauty & Fashion', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Food & Beverage', value: 'Food & Beverage', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Clothing & Accessories', value: 'Clothing & Accessories', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Music & Dance', value: 'Music & Dance', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Personal Blog', value: 'Personal Blog', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Media', value: 'Media', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Baby', value: 'Baby', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Sports, Fitness & Outdoors', value: 'Sports, Fitness & Outdoors', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Education & Training', value: 'Education & Training', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Pets', value: 'Pets', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Travel & Tourism', value: 'Travel & Tourism', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Brand', value: 'Brand', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Software & Apps', value: 'Software & Apps', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Life Style', value: 'Life Style', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Art', value: 'Art', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Health', value: 'Health', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Consultant & Service', value: 'Consultant & Service', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'IT & High Tech', value: 'IT & High Tech', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Finance', value: 'Finance', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Gaming', value: 'Gaming', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Government & Politics', value: 'Government & Politics', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'NGO', value: 'NGO', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Professional Services', value: 'Professional Services', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Automotive & Transportation', value: 'Automotive & Transportation', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Public Figure', value: 'Public Figure', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Public Administration', value: 'Public Administration', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Real Estate', value: 'Real Estate', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Restaurant & Bar', value: 'Restaurant & Bar', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Food & Cooking', value: 'Food & Cooking', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
        { name: 'Other', value: 'Other', studio_image: '', showExampleMask: false, showUploadMask: false, check: false },
      ],
      // 选择的日期（1-31天）
      days: Array.from({ length: 31 }, (_, i) => ({
        value: (i + 1).toString().padStart(2, '0'),
        text: (i + 1).toString(),
      })),
      months: [
        { value: '01', text: 'January' },
        { value: '02', text: 'February' },
        { value: '03', text: 'March' },
        { value: '04', text: 'April' },
        { value: '05', text: 'May' },
        { value: '06', text: 'June' },
        { value: '07', text: 'July' },
        { value: '08', text: 'August' },
        { value: '09', text: 'September' },
        { value: '10', text: 'October' },
        { value: '11', text: 'November' },
        { value: '12', text: 'December' },
      ],
      customErrorMsg: '',
      showMaxImage: false,
      viewSrc: ''
    }
  },
  created() {

  },
  mounted() {

    this.form.influencerId = this.$route.query.influencerId
    // this.form.influencerId = 123456789
    recordButtonClick({ type: 'JoinVIPNow' })
    this.sendData()
    this.selectedCountry = {
      code: 'US',
      name: 'United States'
    }
    window.onresize = () => {

    }
  },
  methods: {
    handleToggleMaxImage(viewSrc, type) {
      this.viewSrc = viewSrc
      this.showMaxImage = type
    },
    customValidate(validateObj, key) {
      let errorMsg = null
      let feedback = true
      if (!this.form[key].length) {
        errorMsg = 'Required'
        feedback = false
      } else if (!/^\d+$/.test(this.form[key])) {
        errorMsg = 'Please enter numbers only'
        feedback = false
      }
      this.customErrorMsg = errorMsg
      validateObj[key] = feedback
      return feedback
    },
    submit() {
      this.selectedCategory.map(item => {
        this.form.categoryTem.push(item.value)
      })
      this.form.category = this.form.categoryTem.join(',')
      let isValidatePass = true
      let params = {
        ...this.form,
      }
      delete params.categoryTem
      for (const key of Object.keys(params)) {
        if (key === 'whatsapp') {
          if (!this.customValidate(this.validateObj, key)) {
            isValidatePass = false
          }
        } else if (key !== 'influencerId') {
          if (!this.handleValidate(this.validateObj, key)) {
            isValidatePass = false
          }
        }
      }
      if (!isValidatePass) {
        return
      }
      recordButtonClick({ type: 'Submit' })
      params.countryPhoneCode = this.selectedCountryCode
      saveInfluencerInfoCollection(params).then(res => {
        if (res.code === 1) {
          this.$router.push({
            name: 'unSubscribe',
            query: {
              type: 'submit',
              influencerId: this.form.influencerId
            }
          })
        }
      })
    },
    handleValidate(validateObj, key) {
      return ['category', 'birthMonth', 'birthDay'].includes(key) ? true : (validateObj[key] = Boolean(this.form[key]))
    },
    handleSelect() {
      this.$nextTick(() => {
        this._setCheck()
      })
    },
    _setCheck() {
      this.platformOptions.forEach((item) => {
        item.check = false
      })
      this.platformOptions.forEach((item) => {
        this.selectedCategory.forEach(it => {
          if (item.name === it.name) {
            item.check = true
          }
        })
      })

    },
    sendData() {

    }
  }
}
</script>
<style lang="scss" scoped>
.form-group {
  width: 97%;
  margin-bottom: 16px;
}

.invalid-feedback {
  bottom: -9px;
}

.b-form-select .dropdown-menu {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* 每行7个 */
  gap: 5px;
  padding: 10px;
}

.b-form-select .dropdown-item {
  text-align: center;
  padding: 5px 0;
  width: 100%;
  /* 确保每个项占满整列 */
}

/* 调整选中状态的样式 */
.b-form-select .dropdown-item.active,
.b-form-select .dropdown-item:focus {
  background-color: #007bff;
  color: white;
}

::v-deep .position-absolute {
  font-weight: 600;
  font-size: 34px;
  position: absolute;
  right: 0;
}

.option__title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
}

.width-200 {
  width: 300px;
}

.apply-btn {
  width: 426px;
  height: 56px;
  background: #4F00C5;
  border-radius: 28px 28px 28px 28px;
  font-weight: 500;
  font-size: 20px;
  color: #FFED59;
  line-height: 40px;
}

.infoFormImg {
  height: 100%;
  margin-right: 258px;

  img {
    height: 100vh;
  }
}

.input-group-text {
  height: 40px;
}

.campaign-detail {
  border-radius: 0px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 12000px;

  .content {
    width: 650px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 32px;

    .camImg {
      height: 64px;
      margin-bottom: 13px;
    }

    .bigTitle {
      font-size: 20px;
      color: #120F1F;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .midLine {
      width: 899px;
      height: 0px;
      border: 1px solid #D4D4D4;
    }

    .textContent {
      font-size: 15px;
      color: #7A7981;
    }

    .youCanTextTitle {
      font-weight: 500;
      font-size: 15px;
      color: #120F1F;
      margin-bottom: 8px;
    }

    .youCanText {
      font-size: 15px;
      color: #120F1F;
      line-height: 20px;
      text-align: center;
      width: 254px;
      margin-bottom: 16px;
    }
  }
}

.viewImg {
  width: 295px;
  height: 274px;
  position: absolute;
  top: 18px;
  left: 220px;
  z-index: 999;
}

.birthBox {
  display: flex;
  flex-wrap: wrap;
  width: 97%;
}

@media (max-width: 1200px) {
  .campaign-detail {
    flex-direction: column;
    padding: 0 12px;

    .content {
      width: 100vw;
    }
  }

  .infoFormImg {
    margin-right: 0;

    img {
      width: 90vw;
      height: auto;
    }
  }

  .apply-btn {
    width: 95vw;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {}

@media only screen and (min-width: 576px) and (max-width: 768px) {}

@media (max-width: 576px) {}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}
</style>
<style lang="scss">
.multiselect__tag-icon:after {
  color: #545454 !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #ffb426 !important;
}

.multiselect__tag {
  background: #FCC03E !important;
  color: #333 !important;
}


.multiselect__option:after {
  display: none;
}

.multiselect {
  .multiselect__element {
    .multiselect__option {
      display: flex;
      align-items: center;

      .option__image {
        width: 16px;
        height: 11px;
        margin-right: 20px;
      }
    }
  }

  .multiselect__tags {

    .multiselect__single {
      display: flex;
      align-items: center;

      .option__image {
        width: 16px;
        height: 11px;
        margin-right: 20px;
      }
    }
  }

  &.multiselect--disabled {
    .multiselect__select {
      color: #999;
      background: #f7f7f8 !important;
    }
  }
}

.multiselect__option--highlight {
  background: #f7f7f8 !important;
  color: #35495e !important;
}

.multiselect__option--selected {
  background: #f7f7f8 !important;
  color: #35495e !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #f7f7f8;
  color: #35495e;
}

#modal-max-image {
  padding: 0 !important;
  overflow: hidden;

  .modal-dialog {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: calc((100vh - 100vw) / 2) auto;

    .modal-content {
      border: none !important;
      border-radius: unset;
    }
  }
}

@media (min-width: 768px) {
  .viewImg {
    left: 0;
  }

  #modal-max-image {
    .modal-dialog {
      width: 700px;
      max-width: 700px;
      height: 700px;
      margin: calc((100vh - 700px) / 2) auto;

      .modal-content {
        border-radius: 8px;
      }
    }
  }
}

.channel {
  padding-bottom: 40px !important;

  .gender {
    line-height: 32px;

    .title {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-right: 10px;
    }

    .form-group {
      width: 97%;

      .custom-control {
        background-color: #e6e6e6;
        padding: 0 !important;
        margin-right: 8px !important;
        border-radius: 16px;

        &.active {
          background-color: #FCC03E;
        }

        .custom-control-label {
          width: 100px;
          text-align: center;
          font-size: 12px;
          color: #333333;

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .interest {
    .form-group {
      margin-bottom: 0;

      .custom-control {
        font-size: 14px;
        color: #333333;
        line-height: 24px;
        margin-bottom: 15px;

        .custom-control-label {
          white-space: nowrap;
        }
      }
    }
  }

  .channel-container {
    .channel-content {}

    .platformInfoBox {
      width: 100%;
      background: #F7F7F8;
      border-radius: 2px 2px 2px 2px;
      padding: 24px;
      justify-content: space-between;

      .platformInfo {
        min-width: 378px;
        margin-bottom: 32px;

        .iconBox {
          display: flex;
          align-items: center;

          .platform-icon {
            width: 20px;
            height: 15px;
            margin-right: 12px;
            object-fit: cover;
          }
        }

        .already-area {
          width: 60px;
          height: 60px;
          position: relative;
          border-radius: 5px;
        }

        .upload-area {
          width: 60px;
          height: 60px;
          position: relative;
          border-radius: 5px;
        }

        .example-image {
          width: 60px;
          height: 60px;
          margin-left: 12px;
          position: relative;
          border-radius: 5px;
          display: flex;
          flex-direction: column;

          .egTips {
            color: #999999;
          }
        }
      }
    }
  }



  .tip-info {
    color: #ff5124;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 12px !important;

    span {
      display: inline-block;
      width: 14px;
      height: 14px;
      line-height: 14px;
      text-align: center;
      font-size: 12px;
      border: 1px solid #ff5124;
      border-radius: 50%;
      margin-right: 4px;
    }
  }

  .example-area {
    margin-top: 8px !important;

    .example-content {
      align-items: center;
      height: 200px;
      background: #f7f7f8;
      padding: 20px;
      border-radius: 8px;

      .example-image {
        position: relative;
        width: 160px;
        height: 160px;
        margin-right: 24px;
      }

      .example-info {
        flex: 1;
        line-height: 18px;
      }
    }
  }

  .mask {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.8);
    text-align: center;
    padding: 15px 0;
    border-radius: 5px;

    a {
      color: #fff;

      &:hover {
        color: #d3ad00;
        text-decoration: underline;
      }
    }
  }

  .row {
    .already-area {
      width: 100px;
      height: 100px;
      position: relative;

      .b-icon {
        position: absolute;
        top: -9px;
        right: -9px;
        font-size: 16px;
        color: #FCC03E;
        cursor: pointer;
      }
    }

    .upload-area {
      position: relative;
      width: 100px;
      height: 100px;
      background: #ffffff;
      border-radius: 4px;

      label {
        width: 100%;
        height: 100%;
        font-size: 28px;
        color: #FCC03E;
        text-align: center;
        line-height: 55px;
        cursor: pointer;
      }
    }

    .width-200 {
      select {
        width: 200px;
      }
    }

    .width-400 {
      input {
        width: 400px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .channel {
    min-height: calc(100vh - 160px);
  }
}

@media (max-width: 992px) {
  .channel {
    .example-area {
      .example-content {
        .example-info {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .row {
      .width-400 {
        input {
          width: 360px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .channel {
    .row {

      input,
      select {
        width: 100% !important;
      }
    }

    .channel-container .platformInfoBox .platformInfo {
      min-width: 100%;
    }
  }
}

@media (max-width: 576px) {
  .viewImg {
    left: 18% !important;
  }

  .birthBox {
    display: block !important;
  }

  .channel {
    padding-bottom: 0 !important;

    .gender {
      .form-group {
        margin-bottom: 8px;

        .custom-control {
          margin-bottom: 16px;
        }
      }
    }

    .example-area {
      .example-content {
        height: 265px;
        justify-content: center;

        .example-info {
          font-size: 12px;
          line-height: 18px;
          flex: unset;
        }
      }
    }

    .tip-info {
      font-size: 12px;
    }

    .operate {
      position: fixed !important;
      bottom: 32px !important;
    }
  }
}
</style>
